<template>
    <div class="container">
    <h1 class="mt-5">Página en Construcción</h1>
    <p class="lead">Vuelva a intentarlo mas tarde por favor.</p>
    <p>Ir a la pagina de <a href="#/portal/inicio"> inicio</a></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>